import React from "react";
import styled from "styled-components";
import { Container, Row } from "react-bootstrap";

import { SectionHeader } from "../components/SectionHeader";
import TextCarousel from "../components/TextCarousel";

import punchingBag from "../assets/imgs/punching-bag-1.png";

const ReviewsSectionStyled = styled.div`
  #reviews-section {
    padding: 80px 0;
    height: 100vh;
    background-size: cover;
    background-color: #f8f9fa;
    background-image: linear-gradient(
        rgba(0, 0, 0, 0.35),
        rgba(40, 41, 42, 0.55),
        rgba(0, 0, 0, 0.35)
      ),
      url(${punchingBag});
    font-weight: 500;
    #review-row {
      height: 100%;
      width: 100%;
      display: flex;
      align-content: center;
      justify-content: center;
      margin: 0;
      .star-wrapper {
          display: flex;
          .star-icon {
              font-size: 35px;
          }
      }
    }
    #header-title {
      text-align: center;
      font-size: 28px;
    }
    .review-text {
      color: #f8f9fa;
      font-size: 1rem;
      overflow-wrap: normal; 
    }
    .review-subtitle {
      font-size: 20px;
    }
  }
  @media (min-width: 576px) {
  }

  /* // Medium devices (tablets, 768px and up) */
  @media (min-width: 768px) {
    #reviews-section {
      height: 550px;
      #header-title {
      font-size: 36px;
    }
      .review-text {
          font-size: 1.5rem;
      }
    }
  }

  /* // Large devices (desktops, 992px and up) */
  @media (min-width: 992px) {
  }

  /* // Extra large devices (large desktops, 1200px and up) */
  @media (min-width: 1200px) {
  }
`;

export const ReviewsSection = (props) => {
  const textCarouselItems = [
    {
      title: (
        <div className="text-center">
            {/* <div className="star-wrapper"> */}
        {/* <FontAwesomeIcon className="star-icon" icon={["fas", "star"]} />
        <FontAwesomeIcon className="star-icon" icon={["fas", "star"]} />
        <FontAwesomeIcon className="star-icon" icon={["fas", "star"]} />
        <FontAwesomeIcon className="star-icon" icon={["fas", "star"]} />
        <FontAwesomeIcon className="star-icon" icon={["fas", "star"]} /> */}
        {/* </div> */}
          <h4 className="review-text">
            “From the first moment I walked in the door, everyone has made me
            feel at home. I have been training in various martial arts for many
            years. Of all the gyms and dojos I have trained at, Legacy is at the
            top of my list. If you are looking for great training, excellent and
            fun coaches, and a strong community, this is the place for you...”
          </h4>
          <div className="review-subtitle text-muted">– Ryan Lafazan</div>
        </div>
      ),
    },
    {
      title: (
        <div className="text-center">
          <h4 className="review-text">
            “I have been coming here for at least two years now, and I still
            really enjoy it. Are the workouts hard? Yes; but I think that's the
            point. I always leave feeling tired, but accomplished. I especially
            like sparing days on Friday. People don't go hard, but it is
            challenging and intimidating. What I like the most, however, are the
            people. All the coaches and students are genuinely nice people.
            That's what makes me come back again and again.”
          </h4>
          <div className="review-subtitle text-muted">– Marco S.</div>
        </div>
      ),
    },
    {
      title: (
        <div className="text-center">
          <h4 className="review-text">
            “This place is amazing. Immediately took me in and made me feel
            comfortable even in the earliest stages. Every trainer is unique and
            absolutely care about helping us grasp the next stage of the
            art....where ever that may be. It's been life changing for me and I
            couldn't be more thankful to be a part of this gym.”
          </h4>
          <div className="review-subtitle text-muted">– Kenny M.</div>
        </div>
      ),
    },
    {
      title: (
        <div className="text-center">
          <h4 className="review-text">
            “I would give this place 20 stars if I could!!! Not only will you
            learn the art of Muay Thai from the world’s greatest instructors,
            but you will be introduced to their culture and way of life!!! This
            was a great experience for me and I am forever grateful for the
            Legacy Muay Thai family”
          </h4>
          <div className="review-subtitle text-muted">– Chanda S.</div>
        </div>
      ),
    },
    {
      title: (
        <div className="text-center">
          <h4 className="review-text">
            “I've trained at a number of different gyms, done martial arts for
            years and boxed and I can say that this place knows there stuff.
            Everybody feels like family after the first day, helpful, nice, and
            supportive. I always look forward to going. Check it out...”
          </h4>
          <div className="review-subtitle text-muted">– Marricio L.</div>
        </div>
      ),
    },
    {
      title: (
        <div className="text-center">
          <h4 className="review-text">
            “Legacy has amazing qualified coaches and genuine owners. It definitely has a family feel compared to other gyms. There are good and authentic people here. It is clean, huge and has a lot of different options for all ages. They have Muay Thai, Jiu-Jitsu, strength and conditioning (my favorite) and more. They are super accommodating if you have to bring your kids along and offer classes for them also. Give this place a try, you won’t regret it.”
          </h4>
          <div className="review-subtitle text-muted">– Susan R.</div>
        </div>
      ),
    },
  ];

  return (
    <ReviewsSectionStyled>
      <Container fluid id="reviews-section">
        <Row id="review-row">
          <SectionHeader title="Hear From Our Legacy Family" />
          <TextCarousel carouselItems={textCarouselItems}></TextCarousel>
        </Row>
      </Container>
    </ReviewsSectionStyled>
  );
};
