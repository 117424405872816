import React from "react";
import { useHistory } from "react-router";
import { Link } from "react-router-dom";
import styled from "styled-components";
import {
  Image,
  Container,
  Row,
  Col,
  Jumbotron,
  Media,
} from "react-bootstrap";

import { NavBar } from "../components/NavBar";
import { ReviewsSection } from "../components/ReviewsSection";
import { Footer } from "../components/Footer";
import { SecondaryButton } from "../components/Buttons";
import { StandardSection } from "../components/StandardSection";
import { SectionHeader } from "../components/SectionHeader";
// import { linkTypes } from "../components/LinkType";

import logo from "../assets/imgs/logo.png";
import heroImage from "../assets/imgs/home-hero.jpg";
import whiteBricks from "../assets/imgs/white-bricks.png";
import muayThaiClass from "../assets/imgs/muay-thai-class-1.jpeg";
import fitnessClass from "../assets/imgs/fitness-class-2.jpeg";
import kidsClass from "../assets/imgs/kids-class-2.jpeg";
import fightTeam from "../assets/imgs/fight-team-1.jpg";
import strengthClass from "../assets/imgs/strength-class-1.jpeg";
import brazilianJiuJitsuClass from "../assets/imgs/brazilianJiuJitsu-class-1.jpeg";
import one from "../assets/imgs/1.svg";
import two from "../assets/imgs/2.svg";
import three from "../assets/imgs/3.svg";
import four from "../assets/imgs/4.svg";

const HomePageStyled = styled.div`
  .hero-enclosure {
    background-image: linear-gradient(
        rgba(40, 41, 42, 1),
        rgba(40, 41, 42, 0.55),
        rgba(0, 0, 0, 0.35),
        rgba(0, 0, 0, 0.35)
      ),
      url(${whiteBricks});
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 0;
    padding-bottom: 0;
    .jumbo-col {
      text-align: center;
    }
    .hero-logo {
      width: 35%;
      margin-top: 35px;
    }
    #hero-title {
      font-weight: 500;
      margin: 30px 0;
      font-size: 25px;
      letter-spacing: 3px;
      font-family: "Norwester", "Montserrat";
      color: #ffd500;
    }
    #hero-subtitle {
      font-size: 14px;
      font-weight: 600;
      margin: 0 0 6px 0;
      text-transform: uppercase;
    }
    #hero-paragraph {
      font-size: 18px;
      font-weight: 500;
      margin: 0 10px 45px 10px;
    }
    #button-col {
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;
      margin-bottom: 60px;
      .hero-buttons {
        width: 100%;
        margin: 5px 0;
      }
    }
    #primary-button {
      margin-top: 2.5em;
      margin-bottom: 20%;
    }
    .ptag-enclosure {
      margin-top: 20px;
      margin-bottom: 20px;
      p {
        margin-bottom: 0px;
        text-align: center;
        font-weight: 400;
      }
    }
  }
  .class-images-container {
    .class-col {
      height: 35vh;
      background-image: url(${heroImage});
      background-position: center;
      background-size: cover;
      display: flex;
      align-items: center;
      justify-content: center;
      overflow: hidden;
      a {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;
      }
      a:hover {
        text-decoration: none;
      }
      h3 {
        color: #ffd500;
        font-weight: 600;
        font-size: 1.6em;
        text-align: center;
      }
    }
    .class-col:hover {
      transition: 1s;
      transform: scale(1.1);
      z-index: 1;
      overflow: hidden;
    }
    #muay-thai-class {
      background-image: linear-gradient(
          rgba(0, 0, 0, 0.35),
          rgba(0, 0, 0, 0.65),
          rgba(0, 0, 0, 0.35)
        ),
        url(${muayThaiClass});
    }
    #kids-class {
      background-image: linear-gradient(
          rgba(0, 0, 0, 0.35),
          rgba(0, 0, 0, 0.65),
          rgba(0, 0, 0, 0.35)
        ),
        url(${kidsClass});
    }
    #fitness-class {
      background-image: linear-gradient(
          rgba(0, 0, 0, 0.35),
          rgba(0, 0, 0, 0.65),
          rgba(0, 0, 0, 0.35)
        ),
        url(${fitnessClass});
      background-position: bottom;
    }
    #fight-team {
      background-image: linear-gradient(
          rgba(0, 0, 0, 0.35),
          rgba(0, 0, 0, 0.65),
          rgba(0, 0, 0, 0.35)
        ),
        url(${fightTeam});
    }
    #strength-class {
      background-image: linear-gradient(
          rgba(0, 0, 0, 0.35),
          rgba(0, 0, 0, 0.65),
          rgba(0, 0, 0, 0.35)
        ),
        url(${strengthClass});
    }
    #brazilianJiuJitsu-class {
      background-image: linear-gradient(
          rgba(0, 0, 0, 0.35),
          rgba(0, 0, 0, 0.65),
          rgba(0, 0, 0, 0.35)
        ),
        url(${brazilianJiuJitsuClass});
    }
  }
  #join-legacy {
    padding: 80px 0;
    background-size: cover;
    background-color: #f8f9fa;
    .join-cards {
      background-color: transparent;
      border: none;
      margin: 20px 0;
      img {
        background-color: #ffd500;
        border-radius: 100%;
        margin-right: 25px;
        padding: 25px;
        height: 7rem;
        width: 7rem;
      }
      p {
        font-size: 0.95rem;
      }
      .card-body {
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
        background-color: #ffffff;
        box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
      }
    }
  }
  #contact-us-section {
    padding-bottom: 0;
    color: #000000;
    background-color: #f8f9fa !important;
  }

  #contact-form-section {
    padding-top: 0;
    color: #000000;
    background-color: #f8f9fa !important;
    h2 {
      color: #000000;
      font-weight: 500;
    }
  }
  @media (min-width: 576px) {
  }

  /* // Medium devices (tablets, 768px and up) */
  @media (min-width: 768px) {
    .hero-enclosure {
      .hero-title-container {
        .hero-title {
          font-size: 80px;
        }
        #hero-subtitle {
          font-size: 24px;
        }
      }
    }
  }

  /* // Large devices (desktops, 992px and up) */
  @media (min-width: 992px) {
    .hero-enclosure {
      padding-top: 30px;
      #button-col {
        flex-direction: row;
        .hero-buttons {
          margin: 0 5px;
          font-size: .9rem;
        }
      }
    }
  }

  /* // Extra large devices (large desktops, 1200px and up) */
  @media (min-width: 1200px) {
  }
`;

export const HomePage = () => {
  const history = useHistory();

  const classesBlock = [
    {
      h3: "Muay Thai Kickboxing",
      id: "muay-thai-class",
      to: "/classes#muay-thai",
    },
    {
      h3: "Youth Muay Thai",
      id: "kids-class",
      to: "/classes#youth-muay-thai",
    },
    // {
    //   h3: "Fitness Kickboxing",
    //   id: "fitness-class",
    //   to: "/classes#fitness-kickboxing",
    // },
    // {
    //   h3: "Legacy Fight Team",
    //   id: "fight-team",
    //   to: "/classes#fight-team",
    // },
    {
      h3: "Strength & Conditioning",
      id: "strength-class",
      to: "/classes#strength",
    },
    {
      h3: "Brazilian Jiu Jitsu",
      id: "brazilianJiuJitsu-class",
      to: "/classes#brazilianJiuJitsu",
    },
  ];

  const joinCards = [
    {
      h4: "Come In For A Free Class!",
      text: "Your first class is free. Just come on in. We have a variety of classes to choose from. See our schedule to choose",
      link: "/schedule",
      linkText: "See our schedule >",
      linktype: "link",
      img: one,
    },
    {
      h4: "Contact Us Or Just Come On By",
      text: "Contact us by the link below to let us know you're coming by or so we can answer any questions you have.",
      link: "/#contact",
      linkText: "Contact and Location >",
      linktype: "hash",
      img: two,
    },
    {
      h4: "Choose From Our Flexible, Training Packages",
      text: (
        <span>
          We offer a variety of packages to work with your busy schedule.
          Whether you need 1-on-1 training, muay thai, or strength & conditioning. We
          have a plan for you. 
          {/* <span>And they're all contract free!</span> */}
        </span>
      ),
      linkText: "",
      linktype: "",
      img: three,
    },
    {
      h4: "Welcome To The Family",
      text: (
        <span>
          We're happy to welcome you to our Legacy Family and are excited to
          join you in your Muay Thai and fitness journey.
        </span>
      ),
      link: "/about",
      linkText: "Learn more about us >",
      linktype: "link",
      img: four,
    },
  ];

  return (
    <HomePageStyled>
      <NavBar />
      <Jumbotron fluid className="hero-enclosure">
        <Container>
          <Row>
            <Col xs={12} sm={{ span: 6, offset: 3 }} className="jumbo-col">
              <Image
                src={logo}
                alt="Legacy Muay Thai Elephant"
                className="hero-logo legacy-logo"
              />
            </Col>
          </Row>
          <Row>
            <Col xs={12} sm={{ span: 6, offset: 3 }} className="jumbo-col">
              <h3 id="hero-title">Join The Legacy Family</h3>
              <p id="hero-paragraph">
                Welcome to Legacy! We are a local, family run martial arts and fitness gym, specializing in Muay Thai Kickboxing and Brazilian Jiu Jitsu, dedicated to our community and our members.
              </p>
            </Col>
          </Row>
          <Row>
            <Col
              xs={12}
              sm={{ span: 6, offset: 3 }}
              className="jumbo-col"
              id="button-col"
            >
              <SecondaryButton className="hero-buttons" buttontype="hash" to="#contact">
                Contact Us
              </SecondaryButton>
              <SecondaryButton className="hero-buttons" buttontype="hash" to="#join-legacy">
                Join Legacy
              </SecondaryButton>
              <SecondaryButton className="hero-buttons" to="/schedule">
                Schedule
              </SecondaryButton>
            </Col>
          </Row>
        </Container>
      </Jumbotron>
      <Container fluid className="class-images-container">
        <Row>
          {classesBlock.map(({ h3, id, to }, i) => (
            <Col xs={12} sm={6} md={6} className="class-col" id={id} key={i}>
              <Link to={to}>
                <h3>{h3}</h3>
              </Link>
            </Col>
          ))}
        </Row>
      </Container>
      <StandardSection idName="join-legacy">
        <SectionHeader title="Join Legacy Today!"></SectionHeader>
        <Row>
          {joinCards.map(({ h4, h4Id, text, link, linkText, linktype, img }, i) => (
            <Col xs={12} sm={12} md={12} lg={6} key={i}>
              <Media className="join-cards" id={`join-cards-${i}`}>
                <Image variant="left" src={img} />
                <Media.Body>
                  <h4 id={h4Id}>{h4}</h4>
                  <p>{text}</p>
                  <linktypes to={link} linktype={linktype}>{linkText}</linktypes>
                </Media.Body>
              </Media>
            </Col>
          ))}
        </Row>
      </StandardSection>
      <ReviewsSection></ReviewsSection>
      <Footer />
    </HomePageStyled>
  );
};
