import React from "react";
import styled from "styled-components";

const SectionHeaderStyled = styled.div`
  #header-title {
    color: #ffd500;
    font-size: 36px;
    font-weight: 600;
    margin: 0 0 24px 0;
  }

  .header-paragraph {
    font-size: 16px;
    font-weight: 400;
    
    @keyframes blink-animation {
      to {
        visibility: hidden;
      }
    }
    @-webkit-keyframes blink-animation {
      to {
        visibility: hidden;
      }
    }
  }
  @media (min-width: 576px) {
  }

  /* // Medium devices (tablets, 768px and up) */
  @media (min-width: 768px) {
  }

  /* // Large devices (desktops, 992px and up) */
  @media (min-width: 992px) {
  }

  /* // Extra large devices (large desktops, 1200px and up) */
  @media (min-width: 1200px) {
  }
`;

export const SectionHeader = (props) => {
  return (
    <SectionHeaderStyled>
      <h3 id="header-title">{props.title}</h3>
      <div className="header-paragraph">
        <div>
         {props.paraLine1}
        </div>
        <div>{props.paraLine2}</div>
        <div>{props.children}</div>
      </div>
    </SectionHeaderStyled>
  );
};
