import React from "react";
import styled from "styled-components";
import { Jumbotron, Container, Row, Col } from "react-bootstrap";

const HeroStyled = styled.div`
  color: white;
  font-weight: 300;
  @media (min-width: 576px) {
  }

  /* // Medium devices (tablets, 768px and up) */
  @media (min-width: 768px) {
  }

  /* // Large devices (desktops, 992px and up) */
  @media (min-width: 992px) {
  }

  /* // Extra large devices (large desktops, 1200px and up) */
  @media (min-width: 1200px) {
  }
`;

export const Hero = (props) => {
  return (
    <HeroStyled>
      <Jumbotron fluid className={props.jumboClass}>
        <Container>
          <Row>
            <Col>
              {props.children}
            </Col>
          </Row>
        </Container>
      </Jumbotron>
    </HeroStyled>
  );
};
