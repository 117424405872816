import React from "react";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import { Image } from "react-bootstrap";

import { NavBar } from "../components/NavBar";
import { Hero } from "../components/Hero";
import { Footer } from "../components/Footer";
import { ClassScheduleSection } from "../components/ClassScheduleSection";

import logo from "../assets/imgs/LegacyIcon-Yellow.png";
import heroImage from "../assets/imgs/boxing-gloves-1.png";

const SchedulePageStyled = styled.div`
  .hero-enclosure {
    display: flex;
    align-items: center;
    height: 30vh;
    background-image: linear-gradient(
        rgba(0, 0, 0, 0.25),
        rgba(0, 0, 0, 0.25),
        rgba(0, 0, 0, 0.55)
      ),
      url(${heroImage});
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    h1 {
      text-transform: uppercase;
      font-weight: 400;
    }
  }
  #about-section {
    padding: 80px 0;
    text-align: justify;
  }
  .contact-nav-icons2 {
    margin-bottom: 80vh;
    display: flex;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    .contact-icon-div a {
      width: 40px;
      height: 40px;
      background-color: #fff;
      font-size: 1.3em;
      display: flex;
      align-items: center;
      justify-content: center;

      border-radius: 50%;
      position: relative;
      overflow: hidden;
      border: 2px solid #ffd500;
      z-index: 1;
    }
    .contact-icon-div a #icon {
      position: relative;
      color: #262626;
      transition: 1s;
      z-index: 3;
      width: 2em;
    }
    .contact-icon-div a:hover {
      border: 2px solid #fff;
      #icon {
        color: #fff;
      }
    }
    .contact-icon-div a:before {
      content: "";
      position: absolute;
      top: 100%;
      left: 0;
      width: 100%;
      height: 100%;
      background: #ffd500;
      transition: 1s;
      z-index: 2;
    }
    .contact-icon-div a:hover:before {
      top: 0;
    }
  }
`;

export const SchedulePage = () => {
  const history = useHistory();
  return (
    <SchedulePageStyled>
      <NavBar />
      <Hero
        jumboClass="hero-enclosure text-center"
      >
          <Image
          src={logo}
          alt="Legacy Muay Thai Elephant"
          className="align-top navbar-logo hero-elephant"
        />
      </Hero>
      <ClassScheduleSection></ClassScheduleSection>
      <Footer />
    </SchedulePageStyled>
  );
};
