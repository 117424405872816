import React from "react";
import styled from "styled-components";
import { Container, Col, Row } from "react-bootstrap";

const StandardSectionStyled = styled.div`
  color: inherit;
  font-weight: bold;
  .page-section {
    padding: 60px 0;
  }
  @media (min-width: 576px) {
  }

  /* // Medium devices (tablets, 768px and up) */
  @media (min-width: 768px) {
    .page-section {
      padding: 80px 0;
    }
  }

  /* // Large devices (desktops, 992px and up) */
  @media (min-width: 992px) {
  }

  /* // Extra large devices (large desktops, 1200px and up) */
  @media (min-width: 1200px) {
  }
`;

export const StandardSection = (props) => {
  return (
    <StandardSectionStyled>
      <section
        className="bg-light page-section"
        className={props.className}
        id={props.idName}
      >
        <Container>
          <Row>
            <Col lg={12} className="text-center">
              <h2 className="section-heading">
                {props.heading}
              </h2>
              <h3 className="section-subheading text-muted">
                {props.subheading}
              </h3>
            </Col>
          </Row>
          <Row>
            <Col lg={12}>{props.children}</Col>
          </Row>
        </Container>
      </section>
    </StandardSectionStyled>
  );
};
