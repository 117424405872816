import React from "react";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import { Image } from "react-bootstrap";

import { NavBar } from "../components/NavBar";
import { Hero } from "../components/Hero";
import { ClassesSection } from "../components/ClassesSection";
import { Footer } from "../components/Footer";

import logo from "../assets/imgs/LegacyIcon-Yellow.png";
import heroImage from "../assets/imgs/boxing-gloves-4.png";

const ClassesPageStyled = styled.div`
  .hero-enclosure {
    display: flex;
    align-items: center;
    height: 30vh;
    background-image: linear-gradient(
        rgba(0, 0, 0, 0.25),
        rgba(0, 0, 0, 0.25),
        rgba(0, 0, 0, 0.55)
      ),
      url(${heroImage});
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    h1 {
      text-transform: uppercase;
      font-weight: 400;
    }
  }
  .classes-section {
    margin: 40px 0;
  }
  @media (min-width: 576px) {
  }

  /* // Medium devices (tablets, 768px and up) */
  @media (min-width: 768px) {
    #classes-section {
      padding-bottom: 140px;
    }
  }

  /* // Large devices (desktops, 992px and up) */
  @media (min-width: 992px) {
  }

  /* // Extra large devices (large desktops, 1200px and up) */
  @media (min-width: 1200px) {
  }
`;

export const ClassesPage = () => {
  const history = useHistory();

  return (
    <ClassesPageStyled>
      <NavBar />
      <Hero jumboClass="hero-enclosure text-center">
        <Image
          src={logo}
          alt="Legacy Muay Thai Elephant"
          className="align-top navbar-logo hero-elephant"
        />
      </Hero>
      <ClassesSection></ClassesSection>
      <Footer />
    </ClassesPageStyled>
  );
};
