import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { Button } from "react-bootstrap";
import { HashLink } from 'react-router-hash-link';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const PrimaryButtonStyled = styled.div`
  #primary-button {
    font-size: 1rem;
    color: #28292a;
    font-weight: bold;
    background-color: #ffd500;
    border-radius: 0px;
    border: 3px solid #ffd500;
    text-transform: uppercase;
    font-family: "Norwester", "Montserrat", sans-serif;
    letter-spacing: 3px;
  }
  #primary-button:hover {
    color: #ffffff;
    background-color: transparent;
    border: 3px solid #ffd500;
  }
`;

const SecondaryButtonStyled = styled.div`
  #secondary-button {
    font-size: 1rem;
    color: #ffffff;
    font-weight: bold;
    background-color: transparent;
    border-radius: 0px;
    border: 3px solid #ffd500;
    text-transform: uppercase;
    font-family: "Norwester", "Montserrat", sans-serif;
    letter-spacing: 3px;
  }
  #secondary-button:hover {
    color: #28292a;
    background-color: #ffd500;
    transition: 0.5s;
  }
`;

const FontAwesomeButtonStyled = styled.div`
  margin: auto;
  // border: 1px solid #000000;
  // border-radius: 15px;
  // padding: 1px;
  #fa-button {
    background: transparent;
    border: none;
    padding: 0px;
    .fa-border {
      border: none;
    }
  }
  #icon {
    color: #fff;
    // margin: 1px;
    // // border: 1px solid #000000;
    // border-radius: 15px;
    // padding: 1px;

    font-size: 30px;
    line-height: 50px;
    display: block;
    width: 35px;
    height: 35px;
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
    color: #808080;
    border-radius: 100%;
    outline: 0;
    /* background-color: #808080; */
  }
`;

export const PrimaryButton = (props) => {
  const linktype = props.buttontype === "hash";
  let button
  if (linktype) {
    button = 
    <HashLink smooth to={props.to}>
      <Button id="primary-button" {...props} />
    </HashLink>;
  } else {
    button = 
    <Link to={props.to}>
      <Button id="primary-button" {...props} />
    </Link>;
  }
  return (
    <PrimaryButtonStyled className={props.className} type={props.buttontype}>
        {button}
    </PrimaryButtonStyled>
  );
};

export const SecondaryButton = (props) => {
  const linktype = props.buttontype === "hash";
  let button
  if (linktype) {
    button = 
    <HashLink smooth to={props.to}>
      <Button id="secondary-button" {...props} />
    </HashLink>;
  } else {
    button = 
    <Link to={props.to}>
      <Button id="secondary-button" {...props} />
    </Link>;
  }
  return (
    <SecondaryButtonStyled className={props.className} type={props.buttontype}>
        {button}
    </SecondaryButtonStyled>
  );
};

// export const PrimaryButton = (props) => {
//   return (
//       <PrimaryButtonStyled className={props.className} type={props.buttontype}>
//         {props.buttontype === "hash" 
//           ? <HashLink smooth to={props.to}>
//               <Button id="primary-button" {...props} />
//             </HashLink> 
//           : <Link to={props.to}>
//               <Button id="primary-button" {...props} />
//             </Link>
//         }
//       </PrimaryButtonStyled>
//   );
// };

// export const SecondaryButton = (props) => {
//   return (
//     <SecondaryButtonStyled className={props.className} type={props.buttontype}>
//       {props.buttontype === "hash" ?
//           <HashLink smooth to={props.to}>
//             <Button id="secondary-button" {...props} />
//           </HashLink> :
//           <Link to={props.to}>
//             <Button id="secondary-button" {...props} />
//           </Link>
//       }
//     </SecondaryButtonStyled>
//   );
// };

export const FontAwesomeButton = (props) => {
  return (
    <FontAwesomeButtonStyled className={props.className} id="icon-div">
      <Button id="fa-button" {...props}>
        <FontAwesomeIcon id="icon" icon={props.icontype} border />
      </Button>
    </FontAwesomeButtonStyled>
  );
};
