import React from "react";
import { HashRouter, Switch, Route } from "react-router-dom";

import { HomePage } from "./pages/HomePage";
import { SchedulePage } from "./pages/SchedulePage";
import { ClassesPage } from "./pages/ClassesPage";
import { AboutUsPage } from "./pages/AboutUsPage";
import { PrivacyPage } from "./pages/PrivacyPage";
import { TermsPage } from "./pages/TermsPage";
import { IMMAFWorldChampionshipPage } from "./pages/IMMAFWorldChampionshipPage";

import { library } from "@fortawesome/fontawesome-svg-core";
import { fab } from "@fortawesome/free-brands-svg-icons";
import { fas } from "@fortawesome/free-solid-svg-icons";
library.add(fab, fas);

function App() {
  return (
    <div className="App">
      <HashRouter>
        <Switch>
          <Route exact path="/" component={HomePage} />
          <Route exact path="/schedule" component={SchedulePage} />
          <Route exact path="/classes" component={ClassesPage} />
          <Route exact path="/about" component={AboutUsPage} />
          <Route exact path="/privacy-policy" component={PrivacyPage} />
          <Route exact path="/terms" component={TermsPage} />
          <Route exact path="/world-championships" component={IMMAFWorldChampionshipPage} />
        </Switch>
      </HashRouter>
    </div>
  );
}

export default App;
