import React from "react";
import styled from "styled-components";
import { Row, Col, Media, Image } from "react-bootstrap";

import { StandardSection } from "../components/StandardSection";
import { SectionHeader } from "../components/SectionHeader";

import muayThaiClass from "../assets/imgs/muay-thai-class-1.jpeg";
import fitnessClass from "../assets/imgs/fitness-class-2.jpeg";
import kidsClass from "../assets/imgs/kids-class-2.jpeg";
import fightTeam from "../assets/imgs/fight-team-1.jpg";
import strengthClass from "../assets/imgs/strength-class-1.jpeg";
import brazilianJiuJitsuClass from "../assets/imgs/brazilianJiuJitsu-class-1.jpeg";
import yiChuanClass from "../assets/imgs/yiChuan-class-1.jpg";

const ClassesSectionStyled = styled.div`
  #classes-section {
    padding: 80px 0;
    background-size: cover;
    background-color: #f8f9fa;
    font-weight: 500;
    .class-cards {
      text-align: center;
      border: none;
      border-radius: 0;
      margin-top: 30px;
      padding: 15px;
      color: #ffd500;
      background-color: #ffffff;
      box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
      img {
        display: none;
      }
      .class-info {
        display: flex;
        flex-wrap: wrap;
        .h4 {
          font-weight: 600;
          font-size: 1.4rem;
          margin-bottom: 1rem;
        }
        p {
          font-weight: 400;
          color: #000000;
          text-align: justify;
          margin: 0;
        }
      }
    }
    .class-cards:hover {
      transition: 0.5s;
      p {
      }
    }
  }
  @media (min-width: 576px) {
  }

  /* // Medium devices (tablets, 768px and up) */
  @media (min-width: 768px) {
    #classes-section {
      padding-bottom: 140px;
      .class-cards {
        img {
          display: unset;
          width: 20%;
        }
      }
    }
  }

  /* // Large devices (desktops, 992px and up) */
  @media (min-width: 992px) {
  }

  /* // Extra large devices (large desktops, 1200px and up) */
  @media (min-width: 1200px) {
  }
`;

export const ClassesSection = (props) => {
  const classesCards = [
    {
      h4: "Muay Thai Kickboxing",
      h4Id: "muay-thai",
      text: "Legacy Muay Thai and Fitness offers an authentic, high-energy Muay Thai Kickboxing program in Sacramento that will motivate you to push yourself more than you ever thought possible. Known as 'The Art of Eight Limbs' because it combines the use of fists, elbows, knees, shins and feet, Muay Thai Kickboxing is a combat sport from Thailand that has become recognized as one of the most effective forms of striking for use in the ring, the cage or as a method of self-defense. Our classes are not only fun and engaging, but they burn body fat, tone muscles and develop flexibility.",
      image: muayThaiClass,
      imageAlt: "muay thai kickboxing class image",
    },
    {
      h4: "Youth Muay Thai",
      h4Id: "youth-muay-thai",
      text: "See a side of your child you've never seen before! When you enroll your child in the action-packed Youth Martial Arts classes at Legacy Muay Thai and Fitness, our expert team of Youth instructors will bring out their inner champion by giving each student a great workout while imparting valuable life skills such as leadership, self-confidence, self-discipline and self-defense that kids can't get from any other activity!",
      image: kidsClass,
      imageAlt: "youth muay thai kickboxing class image",
    },
    {
      h4: "Muay Thai Sparring",
      h4Id: "muay-thai-sparring",
      text: "After taking some time to learn the proper techniques and increasing your fitness level, at Legacy you’ll have the opportunity to engage in some controlled sparring sessions under the watchful eye of our Muay Thai coaches. Controlled sparring is the most effective way to demonstrate and hone your Muay Thai overall skillset. Valuable lessons will be learned with regards to your striking skills, reflexes, and overall offense vs. defense. For many, sparring is the most enjoyable part of the training. At Legacy, we spar every Friday night.",
      image: fitnessClass,
      imageAlt: "muay thai sparring image",
    },
    // {
    //   h4: "Fitness Kickboxing",
    //   h4Id: "fitness-kickboxing",
    //   text: "Legacy's Fitness Kickboxing program was designed for all ability and fitness levels and are perfect for those looking to build lean muscle, lose weight, improve cardiovascular conditioning and, of course, get empowered! For fun, fitness and self-defense Legacy Muay Thai and Fitness invites you to try the ultimate fat burning workout! Our Fitness Kickboxing class is a high-energy, exciting, explosive fitness and conditioning workout that combines the kicks of Kickboxing with the punches of boxing!",
    //   image: fitnessClass,
    //   imageAlt: "fitness kickboxing class image",
    // },
    // {
    //   h4: "Legacy Fight Team",
    //   h4Id: "fight-team",
    //   text: "Fight team. Lorem ipsum dolor sit amet consectetur adipisicing elit. Aliquam consectetur recusandae in autem! Inventore repellat quasi nihil iste ut aperiam. Sed nesciunt quod adipisci deserunt quae repudiandae nostrum. Error, eos.",
    //   image: fightTeam,
    //   imageAlt: "legacy fight team image",
    // },
    {
      h4: "Strength & Conditioning",
      h4Id: "strength",
      text: "The Legacy Strength & Conditioning program focuses on movement quality as well as functional fitness. Our methodology introduces Olympic weightlifting, strength training, plyometrics, mobility, core stability and muscular endurance as well as speed and agility, and cardiovascular endurance. Every session is different, and every session will be a challenge, but every session is fun and exciting. At the end of each session, you’ll know you had a quality workout. Come see how Legacy can improve your overall fitness level and help you exceed your fitness goals.",
      image: strengthClass,
      imageAlt: "strength and conditioning image",
    },
    {
      h4: "Brazilian Jiu Jitsu",
      h4Id: "brazilianJiuJitsu",
      text: "Our Legacy Brazilian Jiu Jitsu class led by Anthony Bivins is designed to take new and experienced BJJ practitioners through beginning and advanced ground fighting techniques. Students will learn core techniques and apply them in a safe and controlled environment. The class will focus on proper form and technique while also providing drills and exercises to further knowledge, skills, and conditioning. Students will receive feedback and progress with opportunities to advance in rank as students gain mastery at each level.",
      image: brazilianJiuJitsuClass,
      imageAlt: "Brazilian Jiu Jitsu class image",
    },
    {
      h4: "Yi Chuan",
      h4Id: "yiChuan",
      text: "Yi Chuan, often called 'Mind Intent Boxing,' is a Chinese martial art that cultivates internal power and mindfulness. Through standing meditation, precise body alignment, and mental focus, practitioners develop strength, balance, and martial skills. This art emphasizes simplicity and directness, making it accessible for all levels. Whether you seek improved health, self-defense capabilities, or a meditative practice, Yi Chuan offers a holistic approach to well-being and martial arts proficiency. Join us to explore the power of the mind and body in this enriching practice.",
      image: yiChuanClass,
      imageAlt: "Yi Chuan class image",
    },
  ];

  return (
    <ClassesSectionStyled>
      <StandardSection idName="classes-section">
        <SectionHeader
          title="Classes"
          paraLine1=""
          paraLine2=""
        ></SectionHeader>
        <Row>
          {classesCards.map(({ h4, h4Id, text, image }, i) => (
            <Col xs={12} sm={12} md={12} lg={12} key={i}>
              <Media className="class-cards" id={`class-cards-${i}`}>
                <Image
                  src={image}
                  alt="Legacy Muay Thai Elephant"
                  className="mr-3 align-top"
                />
                <Media.Body className="class-info">
                  <h4 id={h4Id}>{h4}</h4>
                  <p>{text}</p>
                </Media.Body>
              </Media>
            </Col>
          ))}
        </Row>
      </StandardSection>
    </ClassesSectionStyled>
  );
};
