import React from "react";
import styled from "styled-components";
import { Row, Col } from "react-bootstrap";

import { StandardSection } from "../components/StandardSection";
import { SectionHeader } from "../components/SectionHeader";
import { SecondaryButton } from "../components/Buttons";

const ClassScheduleSectionStyled = styled.div`
  #schedule-nav-buttons {
    padding: 40px 0 0 0;
    background-color: #f8f9fa;
    .schedule-buttons {
      color: #28292a;
      margin: 10px 0;
      text-align: center;
    }
  }
  #class-schedule-section {
    padding: 40px 0;
    background-size: cover;
    background-color: #f8f9fa;
    font-weight: 500;
    h3 {
      margin-top: 15px;
    }
    .arrowSVG {
      display: unset;
    }
    .iframe-container {
      position: relative;
      width: 100%;
      height: 45vh;
      overflow: hidden;
      padding-top: 37%;
      margin-bottom: 15px;
    }
    #strength-iframe-container {
      padding-top: 44%;
    }
    iframe {
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      width: 100%;
      height: 100%;
      border: none;
    }
  }
  @media (min-width: 576px) {
  }

  /* // Medium devices (tablets, 768px and up) */
  @media (min-width: 768px) {
    #class-schedule-section {
      padding-bottom: 140px;
      .arrowSVG {
        display: none;
      }
      .iframe-container {
        height: 35vh;
      }
    }
  }

  /* // Large devices (desktops, 992px and up) */
  @media (min-width: 992px) {
  }

  /* // Extra large devices (large desktops, 1200px and up) */
  @media (min-width: 1200px) {
  }
`;

export const ClassScheduleSection = (props) => {
  const arrowSVG = (
    <div className="arrowSVG">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="32"
        height="32"
        fillRule="currentColor"
        className="bi bi-arrow-right"
        viewBox="0 0 16 16"
      >
        <path
          fillRule="evenodd"
          d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z"
        />
      </svg>
    </div>
  );
  return (
    <ClassScheduleSectionStyled>
      <StandardSection idName="schedule-nav-buttons">
        <Row className="mb-6">
          <Col xs={6} sm={3}>
            <SecondaryButton
              className="schedule-buttons"
              buttontype="hash"
              to="#mt-schedule"
            >
              Muay Thai
            </SecondaryButton>
          </Col>
          <Col xs={6} sm={3}>
            <SecondaryButton
              className="schedule-buttons"
              buttontype="hash"
              to="#str-schedule"
            >
              Strength
            </SecondaryButton>
          </Col>
          <Col xs={6} sm={3}>
            <SecondaryButton
              className="schedule-buttons"
              buttontype="hash"
              to="#BJJ-schedule"
            >
              Jiu Jitsu
            </SecondaryButton>
          </Col>
          <Col xs={6} sm={3}>
            <SecondaryButton
              className="schedule-buttons"
              buttontype="hash"
              to="#yichuan-schedule"
            >
              Yi Chuan
            </SecondaryButton>
          </Col>
        </Row>
      </StandardSection>
      <StandardSection idName="class-schedule-section">
        <SectionHeader
          title="Class Schedule"
          paraLine1=""
          paraLine2=""
        ></SectionHeader>
        <Row>
          <Col>
            <h3 id="mt-schedule">Muay Thai &amp; Fitness</h3>
            {arrowSVG}
            <div className="iframe-container">
              <iframe
                scrolling="yes"
                src="https://docs.google.com/spreadsheets/d/e/2PACX-1vQC8Ng3gGEXRrFSU4ldTFEW2uKqKZ-YxyytnLxDs7sRXOkSG8NIbCFjuXn2-NqYGCB7mmlHFADja-bX/pubhtml?gid=0&amp;&range=A1:F13&amp;single=false&amp;widget=false&amp;headers=false&chrome=false"
              ></iframe>
            </div>
          </Col>
        </Row>
        <Row>
          <Col>
            <h3 id="str-schedule">Strength &amp; Conditioning</h3>
            {arrowSVG}
            <div className="iframe-container" id="strength-iframe-container">
              <iframe src="https://docs.google.com/spreadsheets/d/e/2PACX-1vSS5wxO_4AzVcY8oGwRe1MP6eaNVrPq_VwcPWDdlIC-FECx5bKJdJQVWEk_zM0PXtG8J8W7Qmd8Hae_/pubhtml?gid=0&amp;&range=A1:F13&amp;single=false&amp;widget=false&amp;headers=false&chrome=false"></iframe>
            </div>
          </Col>
        </Row>
        <Row>
          <Col>
            <h3 id="BJJ-schedule">Jiu Jitsu</h3>
            {arrowSVG}
            <div className="iframe-container" id="strength-iframe-container">
              <iframe src="https://docs.google.com/spreadsheets/d/1DNNK6_No89JOAHpAwkvea-UwBk1ox14P9mKISg7Ulx8/pubhtml?gid=0&amp;&range=A1:F13&amp;single=false&amp;widget=false&amp;headers=false&chrome=false"></iframe>
            </div>
          </Col>
        </Row>
        <Row>
          <Col>
            <h3 id="yichuan-schedule">Yi Chuan</h3>
            {arrowSVG}
            <div className="iframe-container" id="strength-iframe-container">
              <iframe src="https://docs.google.com/spreadsheets/d/1pgleIOrPOBV9oBVOln_QQOZFttUTScRfMzJsluN20Wc/pubhtml?gid=0&amp;&range=A1:F9&amp;single=false&amp;widget=false&amp;headers=false&chrome=false"></iframe>
            </div>
          </Col>
        </Row>
      </StandardSection>
    </ClassScheduleSectionStyled>
  );
};
