import React from "react";
import styled from "styled-components";
import { Row, Col, Card, Image } from "react-bootstrap";
import { HashLink } from "react-router-hash-link";

import { StandardSection } from "../components/StandardSection";
import { SectionHeader } from "../components/SectionHeader";

import legacyFamily from "../assets/imgs/legacy-family.jpg";
import kidsCreed from "../assets/imgs/kids-class-creed.jpg";
import nakMuay4 from "../assets/imgs/nak-muay-4.svg";
import ricky1 from "../assets/imgs/ricky-1.jpeg";
import marcos1 from "../assets/imgs/marcos-1.jpeg";
import kirby1 from "../assets/imgs/kirby-1.jpeg";

const AboutUsSectionStyled = styled.div`
  #about-us-section {
    padding: 40px 0;
    background-size: cover;
    background-color: #f8f9fa;
    font-weight: 500;
    .about-legacy-row {
      #about-legacy-col {
        display: none;
        img {
          display: none;
        }
      }
    }

    .creed-row {
      #creed-img-col {
        display: none;
        img {
          display: none;
        }
      }
      #our-creed {
        display: flex;
        flex-direction: column;
        span {
          margin-left: 20px;
        }
      }
    }
    #coach-nav {
      text-align: center;
      .row {
        align-items: center;
        justify-content: center;
      }
      .coach-nav-col {
        display: flex;
        justify-content: center;
      }
      .coach-nav-cards {
        margin-top: 15px;
        margin-bottom: 15px;
        /* background-color: transparent; */
        border: 3px solid #231f20;
        transition: transform 0.5s;
        overflow: hidden;
        border-radius: 5em;
        aspect-ratio: 1 / 1;
        /* height: 10em;
        width: 10em; */
        max-width: 100%;
        max-height: 100%;
        display: none;
        align-items: center;
        justify-content: center;
        background-size: contain;
        .coach-nav-text-wrapper {
          width: 100%;
          height: 20%;
          background-color: #ffffffc9;
          display: flex;
          align-items: center;
          justify-content: center;
          h6 {
            margin-bottom: 0;
            color: #000000;
          }
        }
        img {
          transition: transform 0.5s;
        }
        .card-body {
          padding: 0.5rem;
        }
      }
      .coach-nav-cards:hover {
        background-color: transparent;
        border: 3px solid #ffd500;
        /* color: #ffd500; */
        font-weight: 600;
        transform: scale(1.1);
        /* text-decoration: none; */
        img {
          transform: scale(1.15);
          overflow: hidden;
        }
      }
    }
    #coach-col {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      .col-12 {
        margin: 0;
        padding: 0;
      }
      .coach-cards {
        text-align: center;
        border: none;
        border-radius: 0;
        padding: 0 10px;
        color: #ffd500;
        background-color: transparent;
        margin-top: 3em;
        margin-bottom: 3em;
        img {
          background-color: transparent;
          padding: 30px 30px 30px 30px;
          border-radius: 100%;
          width: 100%;
          aspect-ratio: 1 / 1;
          margin: auto;
        }
        .card-body {
          background-color: #ffffff;
          box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
          /* height: 9em; */
          /* display: flex; */
          align-items: center;
          justify-content: center;
          align-content: center;
          div {
            /* margin: 5px 0; */
            span {
              margin: 15px 0;
              display: flex;
            }
          }
        }
        .h5 {
          color: #28292a;
          font-weight: 600;
          font-size: 1.4rem;
          margin-bottom: 15px;
        }
        .h6 {
          color: #28292a;
          /* margin-bottom: 20px; */
        }
        hr {
          margin-top: 0.5rem;
        }
        p {
          font-weight: 400;
          color: #000000;
          text-align: justify;
        }
      }
      /* .coach-cards:hover {
        padding: 12px;
        margin-bottom: -4px;
        p {
          font-weight: 500;
        }
      } */
    }
  }
  @media (min-width: 576px) {
    #about-us-section {
      #coach-nav {
        .coach-nav-cards {
          display: flex;
          height: 8em;
          width: 8em;
        }
      }
    }
  }

  /* // Medium devices (tablets, 768px and up) */
  @media (min-width: 768px) {
    #about-us-section {
      .about-legacy-row {
        #about-legacy-col {
          background-image: url(${legacyFamily});
          display: unset;
        }
      }
      .creed-row {
        #creed-img-col {
          display: unset;
          background-image: url(${kidsCreed});
          background-position: center;
          margin: 4rem 1rem 1rem 8.33%;
        }
      }
      #coach-nav {
        .coach-nav-cards {
          /* display: flex; */
          height: 7em;
          width: 7em;
          .card-body {
            padding: 1rem;
          }
        }
      }
      #coach-col {
        .coach-cards {
          img {
            width: 60%;
          }
        }
      }
    }
  }

  /* // Large devices (desktops, 992px and up) */
  @media (min-width: 992px) {
    #about-us-section {
      #coach-nav {
        .coach-nav-cards {
          height: 10em;
          width: 10em;
          .card-body {
            padding: 1rem;
          }
        }
      }
    }
  }

  /* // Extra large devices (large desktops, 1200px and up) */
  @media (min-width: 1200px) {
  }
`;

export const AboutUsSection = (props) => {
  const coachCards = [
    {
      h4: "Coach Ricky",
      subtitle: "Muay Thai | Youth Muay Thai | Strength | Powerlifting",
      bio: (
        <div>
          <span>
            Ricky Penn is an instructor for the adult and kid’s program at
            Legacy MUAY THAI and fitness. Ricky has an extensive training
            background in Brazilian Jiu-Jitsu, Muay Thai Kickboxing, wrestling
            and boxing in addition to various other Martial Arts. He has more
            than 20 years of MMA experience and has had the opportunity to train
            with Sacramento’s finest instructors including Cassio Werneck,
            Urijah Faber, Chris David, Andy Kay, Master Thong and Anvar
            Boynazarov. Ricky's natural talent for teaching and people skills
            make him an incredible addition to the Legacy Muay Thai and Fitness
            Team.
          </span>
          <span>
            Aside from his MMA interests, Ricky is a Certified Nutritionist with
            full of passion to it. His knowledge of health, nutrition and
            fitness is quite vast and members learn more than just MMA
            techniques in his classes.
          </span>
          <span>Ricky's favorite quote "Knowledge is Power"</span>
        </div>
      ),
      img: ricky1,
    },
    {
      h4: "Coach Marcos",
      subtitle: "Muay Thai | Youth Muay Thai",
      bio: (
        <div>
          <span>
            Born and raised in Sacramento, I started Muay thai back in 2009 and learned many things through out the years. I’m third generation from the original style of Muay Thai I practice and am still practicing to this day. I come from a very traditional background of Muay Thai but I’ve added my own flavor to it from other martial arts I’ve studied along the way. 
          </span>
          <span>  
            I am an active fighter, I compete both in Muay Thai and MMA. I became the 153 pound super welterweight champion in 2019 under Muay Thai Global promotions. 
          </span>
          <span>
            Other arts I’ve studied on my martial arts journey are boxing, jujitsu, taekwondo, wrestling and wind chun. I also have many years of experiences in Chi Gong which is the essence of my style of Muay Thai. 
          </span>
          <span>  
            I must to pay homage to my teachers/coaches, Kru Yong my Muay Thai coach, Brito my MMA coach and my Uncle Rob who’s is my Wing Chun and Chi Gong coach. Without their teachings I wouldn’t be the fighter I am today.
          </span>
        </div>
      ),
      img: marcos1,
    },
    {
      h4: "Coach Keo",
      subtitle: "Muay Thai | Youth Muay Thai",
      bio: "Bio Coming Soon",
      img: nakMuay4,
    },
    {
      h4: "Coach Koby",
      subtitle: "Muay Thai | Youth Muay Thai",
      bio: "Bio Coming Soon",
      img: nakMuay4,
    },
    {
      h4: "Coach Matthew",
      subtitle: "Muay Thai | Youth Muay Thai",
      bio: "Bio Coming Soon",
      img: nakMuay4,
    },
    {
      h4: "Coach Anthony",
      subtitle: "Jiu Jitsu | Youth Jiu Jitsu | Muay Thai | Youth Muay Thai",
      bio: "Bio Coming Soon",
      img: nakMuay4,
    },
    {
      h4: "Coach Kirby",
      subtitle: "Strength | Powerlifting",
      bio: (
        <div>
          <span>
            RAISED IN CHARLOTTE NORTH CAROLINA AND IM THE OLDEST OF TWO
            BROTHERS. I am a father to an awesome 15 year old son.
          </span>
          <span>
            I served 8 years in the Army with 1 tour deployment to Iraq 07-08. I
            moved to Sacramento in 2012 and I’ve been here going on almost 11
            years now. I’ve done a lot out here everything from participating in
            Tough Mudders and Spartan races to eventually working for Spartan
            via a company called Fitfour. I’ve competed on the show American
            Ninja Warrior 2015 but didn’t go very far and did a local dancing
            with the stars event in Yuba City. So this is just to name a few of
            the things I’ve done.
          </span>
          <span>
            I hold an associates in health and fitness where I also obtain my
            certification with NASAM. I truly enjoy working with anyone who’s
            wanting my help. I believe fitness is very therapeutic and it can
            really help not with just overall health but mental health as well.
          </span>
          <span>
            My specialties
            <br />
            Calisthenics
            <br />
            Powerlifting
            <br />
            Obstacle course racing
          </span>
        </div>
      ),
      img: kirby1,
    },
    {
      h4: "Coach Patrick",
      subtitle: "Strength | Powerlifting",
      bio: "Bio Coming Soon",
      img: nakMuay4,
    },
    {
      h4: "Coach Jeff",
      subtitle: "Strength | Powerlifting",
      bio: "Bio Coming Soon",
      img: nakMuay4,
    },
  ];

  return (
    <AboutUsSectionStyled>
      <StandardSection idName="about-us-section">
        <Row className="about-legacy-row">
          <Col xs={12} sm={12} md={{ span: 5, offset: 1 }}>
            <SectionHeader title="About Legacy"></SectionHeader>
            <p>
              Legacy Muay Thai & Fitness is family owned and operated. We take
              this family oriented business and extends down to our members.
            </p>
            <p>
              We have a 6,500 sqft facility specializing in the art of muay
              thai, fitness, strength & conditioning, and powerlifting classes
              and personal training.
            </p>
          </Col>
          <Col xs={12} sm={12} md={5} id="about-legacy-col">
            <Image src={legacyFamily} alt="Legacy members image" id="" />
          </Col>
        </Row>
      </StandardSection>
      <StandardSection idName="about-us-section">
        <Row className="creed-row">
          <Col xs={12} sm={12} md={{ span: 5, offset: 1 }} id="creed-img-col">
            <Image src={kidsCreed} alt="Legacy members image" id="" />
          </Col>
          <Col xs={12} sm={12} md={5} id="our-creed">
            <SectionHeader title="Our Creed"></SectionHeader>
            <p>
              I will be a good person; I will honor my mother,
              <br />
              <span>my father, and my teacher.</span>
            </p>
            <p>
              I will be loyal to my family and my friends and help
              <br />
              <span>them when needed. I will respect my elders.</span>
            </p>
            <p>I will be truthful; I will treat other fairly.</p>
            <p>
              I will treat other with dignity and respect;
              <br />
              <span>I will not take advantage of others.</span>
            </p>
            <p>I will know right from wrong.</p>
          </Col>
        </Row>
      </StandardSection>
      <StandardSection idName="about-us-section">
        <Row>
          <Col xs={12} sm={12} md={{ span: 10, offset: 1 }}>
            <SectionHeader title="Coaches"></SectionHeader>
          </Col>
          <Col xs={12} sm={12} md={{ span: 10, offset: 1 }} id="coach-nav">
            <Row>
              {coachCards.map(({ h4, h4Id, img }, i) => (
                <Col
                  xs={4}
                  sm={4}
                  md={3}
                  lg={3}
                  key={i}
                  className="coach-nav-col"
                >
                  <HashLink smooth to={`#coach-cards-${i}`}>
                    <Card
                      className="coach-nav-cards"
                      id={`coach-nav-cards-${i}`}
                      style={{ backgroundImage: `url(${img})` }}
                    >
                      <div className="coach-nav-text-wrapper">
                        <h6 id={h4Id}>{h4}</h6>
                      </div>
                    </Card>
                  </HashLink>
                </Col>
              ))}
            </Row>
          </Col>
          <Col xs={12} sm={12} md={{ span: 10, offset: 1 }} id="coach-col">
            {coachCards.map(({ h4, h4Id, subtitle, subId, bio, img }, i) => (
              <Col xs={12} sm={12} md={12} lg={12} key={i}>
                <Card className="coach-cards" id={`coach-cards-${i}`}>
                  <Card.Img variant="top" src={img} />
                  <Card.Body>
                    <Card.Title id={h4Id}>{h4}</Card.Title>
                    <Card.Subtitle id={subId}>{subtitle}</Card.Subtitle>
                    <hr></hr>
                    <Card.Text>{bio}</Card.Text>
                  </Card.Body>
                </Card>
              </Col>
            ))}
          </Col>
        </Row>
      </StandardSection>
    </AboutUsSectionStyled>
  );
};
