import React from "react";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import { Row, Col, Card, Image } from "react-bootstrap";

import { NavBar } from "../components/NavBar";
import { Hero } from "../components/Hero";
import { StandardSection } from "../components/StandardSection";
import { PrimaryButton } from "../components/Buttons";
import { SectionHeader } from "../components/SectionHeader";
import { Footer } from "../components/Footer";

import logo from "../assets/imgs/LegacyIcon-Yellow.png";
import heroImage from "../assets/imgs/immaf-world-championships-banner.jpg";
import IMMAFCollage from "../assets/imgs/IMMAF-World-Collage.svg";
import legacyYouthFighters2023 from "../assets/imgs/Legacy-Youth-Fighters-2023.jpg";
import nakMuay4 from "../assets/imgs/nak-muay-4.svg";
import brooklyn from "../assets/imgs/BrooklynHarper.jpg";
import adam from "../assets/imgs/AdamMaldonadoJr.jpg";
import cora from "../assets/imgs/CoraMacLeod.jpg";

const IMMAFWorldChampionshipPageStyled = styled.div`
  .hero-enclosure {
    display: flex;
    align-items: center;
    height: 30vh;
    background-image: linear-gradient(
        rgba(0, 0, 0, 0.25),
        rgba(0, 0, 0, 0.25),
        rgba(0, 0, 0, 0.55)
      ),
      url(${heroImage});
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    h1 {
      text-transform: uppercase;
      font-weight: 400;
    }
  }
  .classes-section {
    margin: 40px 0;
  }
  #support-section {
    padding: 80px 0;
    background-size: cover;
    background-color: #f8f9fa;
    font-weight: 500;
    text-align: center;
    .header-paragraph {
      margin-bottom: 60px;
      font-size: larger;
    }
    #button-col {
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;
      margin-bottom: 60px;
      #primary-button:hover {
        color: #28292a;
      }
      .hero-buttons {
        width: 100%;
        margin: 5px 0;
      }
    }
    .donate-header, .fighter-article-header {
      margin-top: 60px;
    }
    .collage-image {
      width: 100%;
      margin-top: 30px;
    }
    .span-yellow {
      color: #fad414;
      font-weight: 700;
    }
    .span-bold {
      font-weight: 700;
    }
    #fighter-section {
      margin-top: 30px;
    
    .fighter-cards {
        text-align: center;
        border: none;
        border-radius: 0;
        /* padding: 0 10px; */
        color: #ffd500;
        background-color: transparent;
        margin-top: 1em;
        margin-bottom: 3em;
        img {
          background-color: transparent;
          /* padding: 20px 30px 30px 30px; */
          border-radius: 100%;
          width: 100%;
          aspect-ratio: 1 / 1;
          margin: auto;
          margin-bottom: 20px;
        }
        .card-body {
          background-color: #ffffff;
          box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
          /* height: 9em; */
          /* display: flex; */
          align-items: center;
          justify-content: center;
          align-content: center;
          div {
            /* margin: 5px 0; */
            span {
              margin: 15px 0;
              display: flex;
            }
          }
        }
        .h5 {
          color: #28292a;
          font-weight: 600;
          font-size: 1.4rem;
          margin-bottom: 15px;
        }
        .h6 {
          color: #28292a;
          /* margin-bottom: 20px; */
        }
        hr {
          margin-top: 0.5rem;
        }
        p, span, div {
          font-weight: 400;
          color: #000000;
          text-align: center;
        }
      }
    }
      /* .fighter-cards:hover {
        padding: 12px;
        margin-bottom: -4px;
        p {
          font-weight: 500;
        }
      } */
    }
  }
  @media (min-width: 576px) {
  }

  /* // Medium devices (tablets, 768px and up) */
  @media (min-width: 768px) {
    #support-section {
      padding-bottom: 140px;
      #fighter-section {
        .fighter-cards {
          img {
            width: 60%;
          }
        }
      }
    }
  }

  /* // Large devices (desktops, 992px and up) */
  @media (min-width: 992px) {
    #support-section {
      #button-col {
        flex-direction: row;
        .hero-buttons {
          margin: 0 5px;
          font-size: .9rem;
        }
      }
      .collage-image {
      width: 80%;
    }
    }
  }

  /* // Extra large devices (large desktops, 1200px and up) */
  @media (min-width: 1200px) {
  }
`;

export const IMMAFWorldChampionshipPage = () => {
  const history = useHistory();

  const fighterCards = [
    {
      h4: "Brooklyn Harper",
      bio: (
        <div>
          <p>15 Years Old</p>
          <p>115 LBS</p>
          <p>Undefeated & Ranked #2 in CA for Muay Thai</p>
          <p>Bronze Medalist - 2023 Youth Pankration National Championships</p>
          <p>"Don't count the days,&#10;&#13; make the days count."</p>
        </div>
      ),
      img: brooklyn,
    },
    {
      h4: "Adam Maldonado Jr",
      bio: (
        <div>
          <p>13 Years Old</p>
          <p>126 LBS</p>
          <p>3X Youth Pankration National Champion</p>
          <p>Bronze Medalist - 2021 Youth Pankration World Championships</p>
          <p>"Never Give Up!"</p>
          <br />
        </div>
      ),
      img: adam,
    },
    {
      h4: "Cora MacLeod",
      bio: (
        <div>
          <p>13 Years Old</p>
          <p>Bantamweight</p>
          <p>2021 & 2023 USFL National Pankration Champion</p>
          <p>
            Silver Medalist - 2022 IMMAF Youth Pankration World Championships
          </p>
          <p>"Drillers are killers"</p>
          <br />
        </div>
      ),
      img: cora,
    },
  ];

  return (
    <IMMAFWorldChampionshipPageStyled>
      <NavBar />
      <Hero jumboClass="hero-enclosure text-center">
        <Image
          src={logo}
          alt="Legacy Muay Thai Elephant"
          className="align-top navbar-logo hero-elephant"
        />
      </Hero>
      <StandardSection idName="support-section">
        <SectionHeader
          title="Support Our Legacy Youth Fighters' Journey to the IMMAF World Championships!"
          paraLine1="Legacy Muay Thai has the privilege of sending 3 of our outstanding
          youth fighters, who have qualified for the Pankration World
          Championship, to Abu Dhabi to represent the United States in
          August 2023."
          paraLine2=""
        ></SectionHeader>
        <Row>
            <Col
              xs={12}
              sm={{ span: 6, offset: 3 }}
              md={{ span: 8, offset: 2 }}
              className="jumbo-col"
              id="button-col"
            >
              <PrimaryButton className="hero-buttons" buttontype="hash" to="#donate-section">
                Donate
              </PrimaryButton>
              <PrimaryButton className="hero-buttons" buttontype="hash" to="#fighter-section">
                Fighter Bios
              </PrimaryButton>
              <PrimaryButton className="hero-buttons" buttontype="hash" to="#article-section">
                Read More
              </PrimaryButton>
            </Col>
          </Row>
        {/* <Row>
          <Col xs={12} md={12}>
            <p>
              Legacy Muay Thai has the privilege of sending 3 of our outstanding
              youth fighters, who have qualified for the Pankration World
              Championship, to Abu Dhabi to represent the United States in
              August 2023.
            </p>
          </Col>
        </Row> */}

        {/* <Image 
        src={legacyYouthFighters2023}
        alt=""
        className="w-100"
        ></Image> */}

        <Row id="fighter-section">
          {fighterCards.map(({ h4, h4Id, subtitle, subId, bio, img }, i) => (
            <Col xs={12} sm={12} md={4} lg={4} key={i}>
              <Card className="fighter-cards" id={`fighter-cards-${i}`}>
                <Card.Img variant="top" src={img} />
                <Card.Body>
                  <Card.Title id={h4Id}>{h4}</Card.Title>
                  <Card.Subtitle id={subId}>{subtitle}</Card.Subtitle>
                  <hr></hr>
                  <Card.Text>{bio}</Card.Text>
                </Card.Body>
              </Card>
            </Col>
          ))}
        </Row>

        <div id="donate-section">
          <h4 className="donate-header">
          Donate Via Venmo to Coach Keo{" "}
          <span className="fw-bold span-yellow">@KeoPhayboun</span> or scan the
          QR Code below:
        </h4>
        <Image
          src={IMMAFCollage}
          alt="Legacy Muay Thai Collage of our Youth Champion Fighters who will be competing at the IMMAF Youth World Championships 2023 with a Venmo donation QR code"
          className="collage-image mr-3 align-top"
        />
        </div>
        
        {/* <Row>
          <Image
            src={legacyYouthFighters2023}
            alt=""
            className="mt-5 w-100"
          ></Image>
        </Row> */}
        <Row id="article-section">
          <h4 className="fighter-article-header mb-5">
            Brooklyn Harper, Adam Maldonado Jr, and Cora MacLeod: Inspiring
            Youth Fighters and Exemplary Martial Artists
          </h4>
          <Col xs={12} md={4}>
            <Image
              src={legacyYouthFighters2023}
              alt=""
              className="w-100 mb-3"
            ></Image>
          </Col>
          <Col xs={12} md={8} className="text-left">
            <p>
              Introducing Brooklyn Harper, Adam Maldonado Jr, and Cora MacLeod -
              a trio of exceptional young fighters who have earned numerous
              youth titles and embody the true spirit of martial arts. These
              talented individuals serve as humble and dedicated students,
              setting remarkable examples for their peers.
            </p>

            <p>
              <span className="span-bold">Brooklyn Harper</span>, with his fierce determination and unwavering
              focus, has showcased incredible skill and technique in various
              martial arts disciplines. His passion for martial arts shines
              through every punch, kick, and submission, earning him accolades
              and respect within the youth fighting community.
            </p>

            <p>
            <span className="span-bold">Adam Maldonado Jr</span>, a rising star in the martial arts world, brings
              a perfect blend of strength, agility, and discipline to the ring.
              His commitment to constant improvement and respectful approach to
              training has propelled him to achieve remarkable victories and
              secure multiple youth titles.
            </p>

            <p>
            <span className="span-bold">Cora MacLeod</span>, a true embodiment of dedication and perseverance,
              has conquered challenges on and off the mat. Her unwavering
              commitment to her craft, coupled with an indomitable spirit, has
              earned her a reputation as an exceptional youth fighter and an
              inspiration to others.
            </p>

            <p>
              Beyond their impressive achievements, these three young fighters
              radiate humility and sportsmanship. They embody the essence of
              martial arts by consistently displaying respect for their
              opponents, coaches, and the values instilled in them. Their
              exemplary conduct serves as a guiding light for their peers,
              reminding everyone that true strength lies not only in physical
              prowess but also in character.
            </p>

            <p>
              Now, these aspiring athletes have the opportunity to represent our
              community on a global stage at the prestigious IMMAF World
              Championships. Your support will contribute to covering their
              travel expenses, registration fees, and training costs, ensuring
              that they have the resources needed to compete at their best and
              bring glory to our{" "}
              <span className="span-bold">Legacy</span>.
            </p>

            <p>
              Join us in rallying behind Brooklyn Harper, Adam Maldonado Jr, and
              Cora MacLeod as they embark on this incredible journey. Together,
              we can provide the support they need to make their mark in the
              world of youth fighting and inspire countless others to pursue
              their dreams with passion and integrity.
            </p>
          </Col>
        </Row>
      </StandardSection>
      <Footer />
    </IMMAFWorldChampionshipPageStyled>
  );
};
