import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Container, Col, Row, Image } from "react-bootstrap";

import logo from "../assets/imgs/logo.png";

const FooterStyled = styled.footer`
  footer {
    color: #ffffff;
    background-color: #28292a;
    font-weight: 400;
    padding: 20px;
    a {
      color: #ffd500;
    }
    #quicklinks div a {
      color: #ffffff
    }
    #quicklinks div a:hover {
      text-decoration: underline;
      text-decoration-color: #ffd500;
    }
    .contact-icons-col {
      display: flex;
      justify-content: center;
      margin: 15px 0;
    }
    .agreements-col {
      display: flex;
      justify-content: center;
      margin: 15px 0;
      .agreements {
        display: flex;
        justify-content: flex-end;
        li a {
          color: #ffd500;
        }
      }
    }
    .footer-info {
      .image-col {
        display: flex;
        justify-content: center;
        #logo {
          width: 60%;
          opacity: 60%;
          margin: 20px 0;
        }
      }

      .info-wrapper {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        margin: 15px 0;
        .info-title {
          font-size: 18px;
          font-weight: 600;
          text-transform: uppercase;
          margin-bottom: 26px;
        }
        span {
          font-weight: 600;
          color: #ffffff;
        }
        a span {
          font-weight: 500;
        }
        #icon {
          margin-right: 14px;
          width: 16px;
          height: 20px;
        }
        .link {
          color: #ffd500;
          font-size: 15px;
          font-weight: 600;
          text-transform: uppercase;
        }
      }
      #contact {
      }
    }
    hr {
      border-color: #b5b5b5;
    }
    .footer-misc {
      .copyright {
        font-size: 1em;
      }
      .contact-nav-icons {
        display: flex;
        justify-content: center;
        .contact-icon-div a {
          width: 40px;
          height: 40px;
          background-color: transparent;
          margin: 0px 5px;
          font-size: 1.3em;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 50%;
          position: relative;
          overflow: hidden;
          border: 2px solid #ffd500;
          z-index: 1;
        }
        .contact-icon-div a #icon {
          position: relative;
          color: #ffd500;
          transition: 0.5s;
          z-index: 3;
          width: 2em;
        }
        .contact-icon-div a:hover {
          #icon {
            color: #28292a;
          }
        }
        .contact-icon-div a:before {
          content: "";
          position: absolute;
          top: 100%;
          left: 0;
          width: 100%;
          height: 100%;
          background: #ffd500;
          transition: 0.5s;
          z-index: 2;
        }
        .contact-icon-div a:hover:before {
          top: 0;
        }
      }
    }
    @media (min-width: 576px) {
    }

    /* // Medium devices (tablets, 768px and up) */
    @media (min-width: 768px) {
      .footer-info {
        .image-col {
          justify-content: flex-start;
        }
      }
      .agreements-col {
        justify-content: flex-end;
      }
    }

    /* // Large devices (desktops, 992px and up) */
    @media (min-width: 992px) {
    }

    /* // Extra large devices (large desktops, 1200px and up) */
    @media (min-width: 1200px) {
    }
  }
`;

export const Footer = () => {
  return (
    <FooterStyled>
      <footer className="footer">
        <Container fluid>
          <Row className="align-items-center footer-info">
            <Col xs={12} sm={6} lg={2} className="image-col">
              <Image
                src={logo}
                alt="Legacy Muay Thai Elephant"
                className="legacy-logo"
                id="logo"
              />
            </Col>
            <Col xs={12} sm={6} lg={3}>
              <div className="info-wrapper" id="quicklinks">
                <h3 className="info-title">Quick Links</h3>
                <div>
                  <Link to="/">Home</Link>
                  <br />
                  <Link to="/about">About</Link>
                  <br />
                  <Link to="/classes">Classes</Link>
                  <br />
                  <Link to="/schedule">Schedule</Link>
                  <br />
                  <Link to="/world-championships">World Championships</Link>
                  <br />
                </div>
              </div>
            </Col>
            <Col xs={12} sm={6} lg={4}>
              <div className="info-wrapper" id="contact">
                <h3 className="info-title">Contact</h3>
                <div>
                  <a href="tel:1-916-594-7571">
                    <FontAwesomeIcon id="icon" icon={["fas", "phone"]} />
                    <span>Gym: 916-594-7571</span>
                  </a>
                  <br />
                  <a href="tel:1-916-320-2504">
                    <FontAwesomeIcon id="icon" icon={["fas", "mobile"]} />
                    <span>Keo: 916-320-2504</span>
                  </a>
                  {/* <br />
                  <a href="mailto:legacymuaythai916@yahoo.com">
                    <FontAwesomeIcon id="icon" icon={["fas", "envelope"]} />
                    <span>legacymuaythai916@yahoo.com</span>
                  </a> */}
                  <br />
                  <a
                    href="https://www.instagram.com/legacymuaythaiandfitness/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <FontAwesomeIcon id="icon" icon={["fab", "instagram"]} />
                    <span>@legacymuaythaiandfitness</span>
                  </a>
                  <br />
                  <a
                    href="https://www.instagram.com/916_strength_conditioning/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <FontAwesomeIcon id="icon" icon={["fab", "instagram"]} />
                    <span>@916_strength_conditioning</span>
                  </a>
                  <br />
                  <a
                    href="https://www.facebook.com/Legacy-Muay-Thai-Fitness-LLC-101850925820362/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <FontAwesomeIcon id="icon" icon={["fab", "facebook-f"]} />
                    <span>@legacymuaythaiandfitnessllc</span>
                  </a>
                  <br />
                </div>
              </div>
            </Col>
            <Col xs={12} sm={6} lg={3}>
              <div className="info-wrapper">
                <h3 className="info-title">Location</h3>
                <div>
                  <span>Legacy Muay Thai &amp; Fitness</span>
                  <br />
                  7324 Folsom Blvd Ste D,
                  <br />
                  Sacramento, CA 95826
                </div>
                <div>
                  <br />
                  <a
                    className="link"
                    href="https://www.google.com/maps/place/Legacy+Muay+Thai+and+Fitness,+7324+Folsom+Blvd+Ste+D,+Sacramento,+CA+95826/@38.5506858,-121.4177817,17z/data=!4m2!3m1!1s0x809adac1e2f7308d:0x43d477cf0c8521ae"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Directions
                  </a>
                </div>
              </div>
            </Col>
          </Row>
          <hr />
          <Row className="align-items-center footer-misc">
            <Col xs={12} sm={12} md={3} className="copyright">
              <div className="company">
                &copy; Legacy Muay Thai &amp; Fitness 2023
              </div>
              <div className="company">
                Website by{" "}
                <a
                  href="https://stactica.com"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Stactica Software
                </a>
              </div>
            </Col>
            <Col xs={12} sm={6} md={6} className="contact-icons-col">
              <div className="contact-nav-icons">
                <div className="contact-icon-div">
                  <a
                    href="https://www.instagram.com/legacymuaythaiandfitness/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <FontAwesomeIcon id="icon" icon={["fab", "instagram"]} />
                  </a>
                </div>
                <div className="contact-icon-div">
                  <a
                    href="https://www.facebook.com/Legacy-Muay-Thai-Fitness-LLC-101850925820362/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <FontAwesomeIcon id="icon" icon={["fab", "facebook-f"]} />
                  </a>
                </div>
              </div>
            </Col>
            <Col xs={12} sm={6} md={3} className="agreements-col">
              <ul className="list-inline agreements">
                <li className="list-inline-item">
                  <Link to="/privacy-policy">Privacy</Link>
                </li>
                <li className="list-inline-item">
                  <Link to="/terms">Terms</Link>
                </li>
              </ul>
            </Col>
          </Row>
        </Container>
      </footer>
    </FooterStyled>
  );
};
