import React from "react";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import { Image } from "react-bootstrap";

import { NavBar } from "../components/NavBar";
import { Hero } from "../components/Hero";
import { StandardSection } from "../components/StandardSection";
import { Footer } from "../components/Footer";

import logo from "../assets/imgs/LegacyIcon-Yellow.png";
import heroImage from "../assets/imgs/boxing-1.png";

const TermsStyled = styled.div`
  .hero-enclosure {
    display: flex;
    align-items: center;
    height: 30vh;
    background-image: linear-gradient(
        rgba(0, 0, 0, 0.25),
        rgba(0, 0, 0, 0.25),
        rgba(0, 0, 0, 0.55)
      ),
      url(${heroImage});
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    h1 {
      text-transform: uppercase;
      font-weight: 400;
    }
  }
`;

export const TermsPage = () => {
  const history = useHistory();

  const capitalName = "LEGACY MUAY THAI & FITNESS";
  const siteName = "legacymuaythai916";
  const siteAddress = "https://legacymuaythai916.com/";
  const siteOperator = "Stactica Software";
  const siteOperatorAddress = "https://stactica.com/";
  const siteContact = "info@stactica.com";

  return (
    <TermsStyled>
      <NavBar />
      <Hero jumboClass="hero-enclosure text-center" title="Terms Policy">
        <Image
          src={logo}
          alt="Legacy Muay Thai Elephant"
          className="align-top navbar-logo hero-elephant"
        />
      </Hero>
      <StandardSection>
        <div className="">
          <div className="row">
            <div className="col-12 col-sm-12 col-md-12">
              <div id="wrap">
                <div>
                  <h1>{capitalName}</h1>
                  <p>Terms and Conditions of {siteAddress}</p>
                </div>
                <div>
                  <h1>Terms and Conditions</h1>
                  <p>Last updated: March 1, 2021</p>
                  <p>
                    These Terms and Conditions ("Terms", "Terms and Conditions")
                    govern your relationship with {siteAddress} website (the
                    "Service") operated by {siteName} ("us", "we", or "our").
                  </p>
                  <p>
                    Please read these Terms and Conditions carefully before
                    using the Service.
                  </p>
                  <p>
                    Your access to and use of the Service is conditioned on your
                    acceptance of and compliance with these Terms. These Terms
                    apply to all visitors, users and others who access or use
                    the Service.
                  </p>
                  <p>
                    By accessing or using the Service you agree to be bound by
                    these Terms. If you disagree with any part of the terms then
                    you may not access the Service.
                  </p>
                  <h2>Intellectual Property</h2>
                  <p>
                    The Service and its original content, features and
                    functionality are and will remain the exclusive property of
                    {siteName} and its licensors. The Service is protected by
                    copyright, trademark, and other laws of both the United
                    States and foreign countries. Our trademarks and trade dress
                    may not be used in connection with any product or service
                    without the prior written consent of {siteName}.
                  </p>
                  <h2>Links To Other Web Sites</h2>
                  <p>
                    Our Service may contain links to third-party web sites or
                    services that are not owned or controlled by {siteName}.
                  </p>
                  <p>
                    {siteName} has no control over, and assumes no
                    responsibility for, the content, privacy policies, or
                    practices of any third party web sites or services. You
                    further acknowledge and agree that {siteName} shall not be
                    responsible or liable, directly or indirectly, for any
                    damage or loss caused or alleged to be caused by or in
                    connection with use of or reliance on any such content,
                    goods or services available on or through any such web sites
                    or services.
                  </p>
                  <p>
                    We strongly advise you to read the terms and conditions and
                    privacy policies of any third-party web sites or services
                    that you visit.
                  </p>
                  <h2>Termination</h2>
                  <p>
                    We may terminate or suspend your access immediately, without
                    prior notice or liability, for any reason whatsoever,
                    including without limitation if you breach the Terms.
                  </p>
                  <p>
                    Upon termination, your right to use the Service will
                    immediately cease.
                  </p>
                  <h2>Governing Law</h2>
                  <p>
                    These Terms shall be governed and construed in accordance
                    with the laws of United States, without regard to its
                    conflict of law provisions.
                  </p>
                  <p>
                    Our failure to enforce any right or provision of these Terms
                    will not be considered a waiver of those rights. If any
                    provision of these Terms is held to be invalid or
                    unenforceable by a court, the remaining provisions of these
                    Terms will remain in effect. These Terms constitute the
                    entire agreement between us regarding our Service, and
                    supersede and replace any prior agreements we might have
                    between us regarding the Service.
                  </p>
                  <h2>Changes</h2>
                  <p>
                    We reserve the right, at our sole discretion, to modify or
                    replace these Terms at any time. If a revision is material
                    we will try to provide at least 30 days notice prior to any
                    new terms taking effect. What constitutes a material change
                    will be determined at our sole discretion.
                  </p>
                  <p>
                    By continuing to access or use our Service after those
                    revisions become effective, you agree to be bound by the
                    revised terms. If you do not agree to the new terms, please
                    stop using the Service.
                  </p>
                  <h2>Contact Us</h2>
                  <p>
                    If you have any questions about these Terms, please contact
                    us.
                  </p>{" "}
                </div>
              </div>
            </div>
          </div>
        </div>
      </StandardSection>
      <Footer />
    </TermsStyled>
  );
};
