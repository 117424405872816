import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { Nav, Navbar, Image } from "react-bootstrap";

import { PrimaryButton } from "./Buttons";

import logo from "../assets/imgs/LegacyIcon-Yellow.png";
import toggle from "../assets/imgs/toggle-button-dualtone-1.svg";

const NavBarStyled = styled.div`
  padding: 5px;
  background-color: #28292a;
  .navigation {
    background-color: #28292a;
    border-top: 5px solid #ffd500;
    height: 80px;
    .navbar-toggler-icon {
      background-image: url(${toggle});
    }
    .show {
      background: #28292a;
      padding: 12px 7px;
      display: flex;
      align-content: space-between;
      flex-wrap: wrap;
    }
    a {
      display: flex;
      align-items: center;
      color: #ffffff;
      font-size: 18px;
      letter-spacing: 3px;
      font-family: "Norwester", "Montserrat";
      .hero-title {
        font-weight: 500;
        margin: 0 0 0 8px;
        font-size: 1.5rem;
        #title-span {
          font-family: "Norwester", "Montserrat";
          color: #ffd500;
        }
      }
    }
    .navbar-logo {
      height: 52px;
    }
    #desktop-logo {
      display: none;
    }
    #mobile-logo {
      display: block;
    }
    .nav-link:hover {
      color: #ffd500;
    }
    #link-divider {
      display: none;
      color: #28292a;
    }
    #right-nav {
      .nav-button:hover {
        transition: 0.5s;
      }
    }
  }
  .phone-button {
    margin-right: 10px;
    display: none;
  }
  .phone-button-mobile {
    display: inherit;
    margin-right: 5px;
  }
  @media (min-width: 576px) {
  }

  /* // Medium devices (tablets, 768px and up) */
  @media (min-width: 768px) {
    .navigation {
      .navbar-logo {
        height: 49px;
        margin-top: 5px;
        margin-bottom: 5px;
      }
      #desktop-logo {
        display: block;
      }
      #mobile-logo {
        display: none;
      }
    }
  }

  /* // Large devices (desktops, 992px and up) */
  @media (min-width: 992px) {
    .navigation {
      .navbar-logo {
        height: 100px;
        margin-top: 10px;
        margin-bottom: 10px;
      }
      #desktop-logo {
        display: block;
      }
      #mobile-logo {
        display: none;
      }
      #link-divider {
        display: flex;
        align-items: center;
        color: grey;
      }
    }
    .phone-button {
      display: inherit;
    }
    .phone-button-mobile {
      display: none;
    }
  }

  /* // Extra large devices (large desktops, 1200px and up) */
  @media (min-width: 1200px) {
  }
`;

export const NavBar = () => {

  const navLinks = [
    {
      text: "Home",
      to: "/",
    },
    {
      text: "About",
      to: "/about",
    },
    {
      text: "Classes",
      to: "/classes",
    },
    {
      text: "Schedule",
      to: "/schedule",
    },
    {
      text: "World Championships",
      to: "/world-championships",
    },
  ];

  return (
    <NavBarStyled>
      
      <Navbar
        collapseOnSelect
        expand="lg"
        variant="none"
        fixed="top"
        className="navigation"
      >
        {/* <div className="w-100 h-20"><h3>Send our Fighters to Compete at the IMMAF Youth World Championships in Abu Dhabi!</h3></div> */}
        <Navbar.Brand href="/" className="justify-content-center">
          <Image
            src={logo}
            alt="Legacy Muay Thai Elephant"
            className="align-top navbar-logo legacy-logo"
          />
          <h1 className="hero-title" id="">
            <span id="title-span">
              LEGACY
            </span>
          </h1>
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse className="navbar-collapse-style" id="responsive-navbar-nav">
          <Nav className="mr-auto text-center">
            {navLinks.map(({ text, to }, i) => {
              if (i < navLinks.length - 1) {
                return <><Link className="nav-link" to={to} key={i}>{text}</Link><span id="link-divider">|</span></>
              } else {
                return <Link className="nav-link" to={to} key={i}>{text}</Link>
              }
            })}
          </Nav>
          <Nav className="ml-auto text-center contact-nav-icons" id="right-nav">
            <PrimaryButton to="/#join-legacy" className="nav-button" buttontype="hash">
              Join Legacy
            </PrimaryButton>
          </Nav>
        </Navbar.Collapse>
      </Navbar>
    </NavBarStyled>
  );
};
